<template>
    <div class="querycommunity">
        <LiefengContent>
            <template v-slot:title>{{ `机构菜单栏目发布统计` }}</template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem label="维度选择" :label-width="75">
                        <!-- resetNum -->
                        <Select transfer style="text-align: left; width: 150px" v-model="search.dimensionId" placeholder="请选择组织维度" @on-change="selectDimension">
                            <Option :value="item.id" v-for="(item, index) in codeOptions" :key="index">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="示范社区" :label-width="75" clearable>
                        <!-- resetNum -->
                        <Select transfer style="text-align: left; width: 180px" v-model="search.onlineType" clearable placeholder="请选择示范社区类型">
                            <Option value="">全部社区</Option>
                            <Option value="1">市级示范社区</Option>
                            <Option value="2">区级示范社区</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="范围" :label-width="75">
                        <CascaderCity @changeCasader="changeCasader" :resetNum="resetNum" :orgCode="4401" :width="250"></CascaderCity>
                    </FormItem>
                    <FormItem label="日期" :label-width="75">
                        <DatePicker :options="options1" @on-change="changeTime" :value="search.statDate" type="date" style="width: 200px" clearable placeholder="请选择日期"></DatePicker>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="request" style="margin-right: 10px">查询</Button>
                    <Button type="primary" @click="exportData" style="margin-right: 10px">导出</Button>
                </Form>
            </template>

            <template v-slot:contentArea>
                <LiefengTable
                    :tableData="pageData.tableData"
                    :talbeColumns="pageData.tableColumns"
                    :loading="loading"
                    :fixTable="true"
                    :pageSize="pageData.pageSize"
                    :total="pageData.total"
                    :pagesizeOpts="[50, 100, 500, 2000]"
                    :curPage="pageData.curPage"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import LiefengTable from "@/components/LiefengTable"
import CascaderCity from "./components/CascaderCity"

export default {
    components: {
        LiefengContent,
        LiefengCascaderCity,
        LiefengTable,
        CascaderCity,
    },
    destroyed() {
        this.data = null
    },
    created() {
        this.selectAuthorizationDimension()
        this.request()
    },
    data() {
        return {
            pageData: {
                tableData: [],
                tableColumns: [
                    { type: "selection", width: 60, align: "center" },
                    { title: "维度名称", key: "dimensionName" },
                    { title: "机构名称", key: "orgName" },
                    { title: "发布信息数量", key: "publishNum" },
                    { title: "已删除内容发布总数", key: "delNum" },
                    { title: "累计发布信息数", key: "allPublishNum" },
                    {title:'服务范围',key:"orgScopeNames"},
                    { title: "区", key: "zoneName" },
                    { title: "街道", key: "streetName" },
                    { title: "社区", key: "communityName" },
                    {
                        title: "操作",
                        width: 120,
                        align: "right",
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                let orgCode = params.row.orgCode
                                                this.openDetail(orgCode)
                                            },
                                        },
                                    },
                                    "查看明细"
                                ),
                            ])
                        },
                    },
                ],
                total: 0,
                pageSize: 50,
                curPage: 0,
            },
            codeOptions: [],
            search: {
                dimensionId: 2,
                orgCode: "",
                onlineType: "",
                statDate: "",
            },
            loading: false,
            options1: {
                disabledDate(date) {
                    return date && date.valueOf() > Date.now() - 86400000
                },
            },
        }
    },
    methods: {
        openDetail(orgCode) {
            layer.open({
                type: 2,
                title: "查看明细",
                area: ["100%", "100%"],
                content: "/page#/orgmenupublish?orgCode=" + orgCode,
                end: function () {},
            })
        },
        changeCasader(val) {
            this.search.orgCode = val[val.length - 1]
        },
        changeTime(val) {
            this.search.statDate = val
        },
        hadlePageSize(obj) {
            this.loading = true
            this.$get("/gateway/api/symanage/pc/orgColumnMenu/selectByPage", {
                pageSize: obj.pageSize,
                page: obj.page,
                ...this.search,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.pageData.tableData = res.dataList
                        this.pageData.pageSize = res.pageSize
                        this.pageData.total = res.maxCount
                        this.pageData.curPage = res.currentPage
                        this.summaryDataList(res.dataList)
                    } else {
                        this.pageData.tableData = []
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败",
                    })
                })
        },
        summaryDataList(list) {
            var publishNum = 0
            var delNum = 0
            var allPublishNum = 0
            list.forEach(d => {
                publishNum += d.publishNum
                delNum += d.delNum
                allPublishNum += d.allPublishNum
            })
            var data = { dimensionName: "合计", publishNum: publishNum, 已删除内容发布总数: delNum, 累计发布信息数: allPublishNum}
            this.pageData.tableData.push(data)
        },
        selectDimension(val) {
            this.search.dimensionId = val
        },
        selectAuthorizationDimension() {
            this.$get(
                "/orgzz/pc/dimension/selectAuthorizationDimension",
                {
                    appCode: "",
                    oemCode: "zjsm",
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                this.codeOptions = [{ id: "", code: "", name: "全部" }]
                if (res.code == 200) {
                    res.dataList.map(item => {
                        if (item) {
                            this.codeOptions.push(item)
                        }
                    })
                }
            })
        },
        request() {
            this.hadlePageSize({ page: 1, pageSize: this.pageData.pageSize })
        },
        // 导出
        exportData() {
            if (this.pageData.tableData.length == 0) {
                this.$Notice.error({
                    title: "当前页面数据为空，不能导出",
                })
                return
            }
            this.$Modal.confirm({
                title: "温馨提示",
                content: `<p>您正在导出统计报表，是否继续？</p>`,
                onOk: () => {
                    this.$Message.loading({
                        content: "正在导出数据，请稍等...",
                        duration: 0,
                    })
                    let tHeader = ["维度名称", "机构名称", "区", "街道", "社区", "发布信息数量", "已删除内容发布总数"]
                    let filterVal = ["dimensionName", "orgName", "zoneName", "streetName", "communityName", "publishNum", "delNum"]
                    let data = JSON.parse(JSON.stringify(this.pageData.tableData))
                    this.$core.exportExcel(tHeader, filterVal, data, "数据报表")
                    this.$Message.destroy()
                },
            })
        },
    },
}
</script>

<style scoped lang="less">
.querycommunity {
    /deep/ .table > .ivu-table > .ivu-table-tip > table > tbody > tr > td {
        height: calc(100vh - 150px);
        border-left: 1px solid #f8f8f9;
        border-bottom: 1px solid #f8f8f9;
        border-right: 1px solid #f8f8f9;
    }
}
</style>