<!--
用途：市、区、街道、社区的级联选择
传参： orgCode:'44', //默认为44,即广东省开始查起
       resetNum:0 , //默认为0，改变了就会触发清空的方法，一般用于重置使用，让resetNum自增1即可
       isClear:false // 默认为false，是否显示清空按钮
       orgLimit:''  // 默认为空，限制当前传入的orgCode，只显示orgCode相等的数据

       isShow:是否需要回显数据，value：需要回显的参数，可以是字符串"4401-440103"/"4401,440103"，也可以是数组['4401','440103']

       如果传进来的是字符串，回显值时候不发生改变可以加上this.$nextTick 给字符串进行重新赋值
暴露出去的方法： changeCasader ，选择了每一项之后的数据

-->

<template>
    <div>
        <Cascader
            @on-clear="changeClear"
            @on-change="changeCasder"
            transfer
            :load-data="loadData"
            change-on-select
            :style="'margin-right:' + right + 'px;' + 'width:' + width + 'px'"
            :data="CascaderList"
            :clearable="isClear"
            v-model="CascaderId"
            :placeholder="orgCode == '44' ? '请选择市/区/街道/社区' : '请选择'"
        ></Cascader>
    </div>
</template>

<script>
export default {
    props: {
        orgCode: {
            type: String,
            default: () => {
                return "44"
            },
        },
        resetNum: 0,
        isClear: {
            type: Boolean,
            default: () => {
                return false
            },
        },
        orgLimit: {
            type: String,
            default: () => {
                return ""
            },
        },
        width: {
            type: String,
            default: () => {
                return 350
            },
        },
        right: {
            type: String,
            default: () => {
                return 10
            },
        },
        value: [String, Array],
        isShow: {
            type: Boolean,
            default: () => {
                return false
            },
        },
    },
    data() {
        return {
            // 存放选择的社区
            CascaderList: [],
            CascaderId: [],
            index: 0,
            showValue: "",
            showMoren: false,
        }
    },
    created() {
        if (this.isShow) {
            if (this.value && this.value != "") {
                this.getShowList(this.value)
            }
            return
        }
        this.getCascader()
    },
    methods: {
        // 点击清除按钮事件
        changeClear() {
            this.CascaderId = []
            this.changeCasder(this.CascaderId)
        },
        // 获取级联
        getCascader() {
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    let data = []
                    if (this.orgLimit && this.orgLimit != "") {
                        res.dataList.map(item => {
                            this.orgLimit.split(",").map(items => {
                                if (items == item.orgCode) data.push(item)
                            })
                            // if (this.orgLimit.indexOf(item.orgCode) > -1) {
                            //     data.push(item)
                            // }
                        })
                    }
                    if (data.length) res.dataList = data
                    this.CascaderList = this.forMatterTree(res.dataList)
                    if (this.CascaderList.length != 0) {
                        if (this.resetNum == 0 && !this.isShow) {
                            this.CascaderId.push(this.CascaderList[0].value)
                            this.changeCasder(this.CascaderId)
                        } else if (this.isShow) {
                            let flag = true
                            res.dataList.map(item => {
                                this.showValue.split(",").map(items => {
                                    if (item.orgCode == items) {
                                        flag = false
                                    }
                                })
                            })
                            if (flag) {
                                this.showMoren = true
                                if (this.index) {
                                    this.CascaderId = [res.dataList[0].orgCode]
                                    this.changeCasder(this.CascaderId)
                                } else {
                                    this.CascaderId.push(res.dataList[0].orgCode)
                                    this.changeCasder(this.CascaderId)
                                }
                            }
                        }
                    }
                    this.CascaderList.map(item => {
                        item.loading = false
                    })
                }
            })
        },
        changeCasder(val, item) {
            this.$emit("changeCasader", val)
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName
                data[i].value = data[i].orgCode
                if (data[i].children && data[i].children != null && data[i].children.length > 0) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        async loadData(item, callback) {
            item.loading = true
            let params = {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: item.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }
            await this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                ...params,
            }).then(res => {
                var resList = []
                var isSame = false //是否有限制并且相同项
                if (this.orgLimit && this.orgLimit != "" && res.dataList && res.dataList.length) {
                    this.orgLimit.split(",").map(items => {
                        res.dataList.map(item =>{
                            if(item.orgCode == items) {
                                isSame = true
                                resList.push(item)
                            }
                        })
                    })
                    if(isSame){
                        res.dataList = resList
                    }
                }
                console.log('dataList',res.dataList);
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items => {
                        if (items.orgLevel == 4) {
                            items.loading = false
                        } else if (items.orgLevel == 3) {
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children)
                    if ((this.resetNum == 0 && !this.isShow) || this.showMoren) {
                        this.CascaderId.push(arr[0].value)
                        this.changeCasder(this.CascaderId)
                    } else if (this.isShow) {
                        //  如果需要回显
                        if (this.showValue.indexOf(",") > -1) {
                            if (this.showValue.split(",").length == this.index) return
                            this.CascaderId.push(this.showValue.split(",")[this.index])
                            this.index++
                            this.changeCasder(this.CascaderId)
                        } else if (this.showValue.indexOf("-") > -1) {
                            if (this.showValue.split("-").length == this.index) return
                            this.CascaderId.push(this.showValue.split("-")[this.index])
                            this.index++
                            this.changeCasder(this.CascaderId)
                        }
                    }
                } else {
                    callback([])
                }
            })
        },
        getShowList(val) {
            this.CascaderId = []
            this.showValue = ""
            this.index = 0
            if (val && val != "") {
                if (typeof val == "object" && val.constructor == Array) {
                    this.showValue = val.join(",")
                    this.index++
                    this.CascaderId = [val[0]]
                } else if (typeof val == "string" && val.constructor == String) {
                    this.showValue = val
                    if (val.indexOf(",") > -1) this.CascaderId = [val.split(",")[this.index]]
                    else if (val.indexOf("-") > -1) this.CascaderId = [val.split("-")[this.index]]
                    this.index++
                }
                this.getCascader()
                this.changeCasder(this.CascaderId)
            }
        },
    },
    watch: {
        resetNum(val) {
            this.CascaderId = []
        },
        value: {
            handler(val) {
                this.getShowList(val)
            },
            deep: true,
            // immediate:true
        },
    },
}
</script>

<style>
</style>